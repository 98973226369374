var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"sm":12}},[_c('el-form-item',{attrs:{"prop":"from"}},[_c('el-time-select',{attrs:{"placeholder":_vm.columns.from.title,"picker-options":{
                        start: _vm.min,
                        step: '00:30',
                        end: _vm.max
                        }},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}})],1)],1),_c('el-col',{attrs:{"sm":12}},[_c('el-form-item',{attrs:{"prop":"to"}},[_c('el-time-select',{attrs:{"placeholder":_vm.columns.to.title,"picker-options":{
                        start: _vm.min,
                        step: '00:30',
                        end: _vm.max,
                        minTime: _vm.form.from
                        }},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"room_id"}},[_c('el-select',{attrs:{"placeholder":_vm.columns.room_id.title},model:{value:(_vm.form.room_id),callback:function ($$v) {_vm.$set(_vm.form, "room_id", $$v)},expression:"form.room_id"}},_vm._l((_vm.rooms),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('el-row',{staticClass:"butm text-right"},[_c('el-button',{attrs:{"type":"success","loading":_vm.loadingButton,"disabled":_vm.loadingButton ? true : false},on:{"click":function($event){return _vm.submit(true)}}},[_vm._v("Сохранить")]),_c('el-button',{on:{"click":function($event){return _vm.resetForm('form')}}},[_vm._v("Отмены")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }