<template>
  <div class="schedule-add-blocks">
    <div class="topSearch">
      <div class="demo-input-suffix">
        <el-input
          :placeholder="$t('message.search_doctor')"
          prefix-icon="el-icon-search"
          v-model="filterForm.search"
          clearable
        >
        </el-input>
      </div>
      <el-select
        filterable
        clearable
        v-model="filterForm.name"
        :placeholder="$t('message.all_speciality')"
      >
        <el-option
          v-for="item in specialties"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </div>

    <div class="ScheduleAdd">
      <table class="table table-bordered" v-loading="loadingData">
        <thead>
          <tr>
            <th colspan="8">{{$t('message.employees_work_schedule')}}</th>
          </tr>
          <tr class="days bacColor">
            <th class="w-20"></th>
            <th class="w-20">пн</th>
            <th class="w-20">вт</th>
            <th class="w-20">ср</th>
            <th class="w-20">чт</th>
            <th class="w-20">пт</th>
            <th class="w-20">сб</th>
            <th class="w-20 colorRED">вс</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in list">
            <template v-if="item.doctors.data.length">
              <tr class="directionType" :key="item.id">
                <td colspan="8">{{ item.name }}</td>
              </tr>
              <tr
                class="bacColor"
                v-for="doctor in item.doctors.data"
                :key="item.name + doctor.id"
              >
                <td>
                  <div class="docName">
                    {{ doctor.name }} {{ doctor.surname }}
                  </div>
                </td>
                <td v-for="res in 7" :key="res">
                  <div
                    class="working-hours"
                    v-for="work_time in doctor.work_times"
                    :key="'work_time' + work_time.id"
                  >
                    <template v-if="work_time.day === res">
                      <span>{{ work_time.from }} - {{ work_time.to }}</span>
                      <span>{{ work_time.room.room_number }}</span>
                      <span
                        class="deletix el-icon-circle-close"
                        @click="destroyModel(work_time)"
                      ></span>
                    </template>
                  </div>
                  <div
                    class="modalAdd"
                    @click="openCreate(item.id, doctor.id, res)"
                  >
                    {{ $t("message.adds") }}
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <el-dialog
      title="Время посещения"
      :visible.sync="drawerCreate"
      :drawer="drawerCreate"
      width="30%"
      center
    >
      <crm-create
        @c-close="closeDrawer"
        :drawerCreate="drawerCreate"
        :specialty="specialty_id"
        :doctor="doctor_id"
        :day="day"
        @closed="fetchData()"
      ></crm-create>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import CrmCreate from "./components/crm-create";
export default {
  name: "WorkTime",
  components: {
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      filterForm: {},
      specialty_id: null,
      doctor_id: null,
      day: 1,
      loadingData: false,
    };
  },
  async created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedGetAnswer = _.debounce(this.fetchData, 500);
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updateFilter(newVal);
        this.debouncedGetAnswer();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      list: "workTimes/list",
      specialties: "specialties/list",
      rooms: "rooms/list",
      filter: "workTimes/filter",
    }),
    actions: function () {
      return ["delete"];
    },
  },
  async mounted() {
    this.fetchData();

    if (this.specialties && this.specialties.length === 0)
      await this.loadSpecialties();
  },
  methods: {
    ...mapActions({
      updateList: "workTimes/index",
      loadSpecialties: "specialties/index",
      delete: "workTimes/destroy",
      updateFilter: "workTimes/updateFilter",
    }),
    fetchData() {
      const query = { ...this.filter };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    closeDrawer(obj) {
      if (obj.reload) {
        this.fetchData();
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    destroyModel(model) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.delete(model.id)
            .then((res) => {
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.fetchData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message:  i18n.t("message.operation_canceled"),
          });
        });
    },
    setItem(specialty_id, doctor_id, day) {
      this.specialty_id = specialty_id;
      this.doctor_id = doctor_id;
      this.day = day;
      return true;
    },
    openCreate(specialty_id, doctor_id, day) {
      if (this.setItem(specialty_id, doctor_id, day)) {
        this.drawerCreate = true;
      }
    },
  },
};
</script>
<style lang="scss">
.schedule-add-blocks {
  .ScheduleAdd {
    tbody > tr > td .modalAdd {
      text-align: center;
      display: block;
      color: #007bff;
      cursor: pointer;
      position: relative;
    }
    .w-20 {
      width: 11%;
    }
    .days {
      font-weight: 700;
      th {
        color: #303030;
      }
    }
    .bacColor {
      background-color: #fff;
    }
    .colorRED {
      color: red !important;
    }
    .directionType {
      td {
        color: #1e75a8;
        font-weight: 700 !important;
      }
    }
    .docName {
      color: #303030;
      font-weight: 700 !important;
      display: flex;
      flex-direction: column;
      span {
        color: #b3b3b3;
        font-weight: 300;
      }
    }
    .working-hours {
      color: #303030;
      font-weight: 600;
      margin-bottom: 5px;
      span {
        margin: 0px 3px;
      }
    }
  }
  .timess {
    display: flex;
    .el-date-editor {
      margin: 0px 10px;
    }
  }
  .el-dialog__wrapper .el-select {
    // margin:  10px;
    display: block;
  }
  .topSearch {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .demo-input-suffix,
    .el-select {
      width: 300px;
      margin: 0 0 0 20px;
    }
  }
  .butm button {
    margin: 0px 10px;
  }
}
.deletix {
  font-size: 16px;
  color: red;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.ScheduleAdd tbody > tr > td {
  position: relative;
  padding: 5px !important;
}
</style>